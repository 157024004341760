import { NextImage, NextLink, noForwardProps } from '@apeiron/library'
import { Box, styled, Typography } from '@mui/material'
import BackgroundAdCard from '@public/images/background-battle-demo-ad-card-title.png'
import BattleDemoTitle from '@src/components/battle/demo/share/BattleDemoTitle'
import ApeironPurpleDivider from '@src/components/share/apeiron/ApeironPurpleDivider'
import ENV_CONFIG from '@src/config'
import { DEFAULT_ANIMATE_DURATION } from '@src/constants/layout'
import { ROUTE } from '@src/constants/route'
import { BattleDemoAd, BattleDemoAdSection } from '@src/types/battleDemo'
import * as R from 'ramda'
import { FC } from 'react'
import { Trans } from 'react-i18next'

const Container = styled(Box, noForwardProps)<ContainerProps>`
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding-bottom: 50px;
  width: 100%;
  position: relative;
`

const Title = styled(BattleDemoTitle)`
  ${props => props.theme.breakpoints.up('res1024')} {
    margin: 45px 0px 0px 90px;
  }
  ${props => props.theme.breakpoints.down('res1024')} {
    margin: 40px 0px 0px 20px;
  }
`

const Divider = styled(ApeironPurpleDivider)`
  align-self: center;
  width: 100%;
  max-width: 1768px;
`

const CardContainer = styled(Box)`
  display: flex;
  justify-content: center;
  width: 100%;
`

const CardList = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  width: 940px;
  max-width: 90%;
`

const Card = styled(Box)`
  background-color: black;
  width: 300px;
  transition: filter ${DEFAULT_ANIMATE_DURATION}ms;
  :hover {
    filter: brightness(1.2);
  }
`

const CardImage = styled(NextImage, noForwardProps)<{ __aspectRatio: number }>`
  aspect-ratio: ${props => props.__aspectRatio};
  width: 100%;
`

const CardInfo = styled(Box)`
  /* padding: 10px 15px 20px 15px; */
`

const CardTitle = styled(Typography)`
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 10px;
`

const CardDescription = styled(Typography)`
  font-size: 14px;
  color: #cccccc;
  white-space: pre-line;
`

const StyledNextLink = styled(NextLink)`
  text-decoration: underline;
  color: #3682eb;
`

const AdCardTitleContainer = styled(Box)`
  background: url(${BackgroundAdCard.src}) no-repeat;
  padding: 4px 10px;
  text-align: center;
`

const CardDescriptionContainer = styled(Box)`
  padding: 10px 15px 20px 15px;
`

const AdCard: FC<AdCardProps> = props => {
  const { adItem, className } = props

  const { image, title, description, imageAspectRatio } = adItem

  return (
    <Card className={className}>
      <CardImage src={image} __aspectRatio={imageAspectRatio} />
      <CardInfo>
        <AdCardTitleContainer>
          <CardTitle>{title}</CardTitle>
        </AdCardTitleContainer>
        <CardDescriptionContainer>
          <CardDescription>
            <Trans
              components={{
                'apostle-mkt-link': (
                  <StyledNextLink href={ROUTE.MARKETPLACE_APOSTLE_LIST} />
                ),
                'planet-mkt-link': (
                  <StyledNextLink href={ROUTE.MARKETPLACE_PLANET_LIST} />
                ),
                'godiverse-mkt-link': (
                  <StyledNextLink href={ROUTE.MARKETPLACE_GODIVERSE_LIST} />
                ),
                'mavis-mkt-link': (
                  <StyledNextLink
                    href={ENV_CONFIG.EXTERNAL_URL.MAVIS_MARKET_GODIVERSE}
                  />
                )
              }}
            >
              {description}
            </Trans>
          </CardDescription>
        </CardDescriptionContainer>
      </CardInfo>
    </Card>
  )
}

const AdCardSection: FC<Props> = props => {
  const { adSection, className, firstAd = false } = props

  const apeironTitle = R.propOr('', 'apeironTitle', adSection) as string

  const title = R.propOr('', 'title', adSection) as string

  const content = R.propOr([], 'content', adSection) as BattleDemoAd[]

  return (
    <Container __firstAd={firstAd} className={className}>
      <Title apeironTitle={apeironTitle} title={title} />
      <Divider />
      <CardContainer>
        <CardList>
          {R.map(
            adItem => (
              <AdCard
                key={`${adItem.title}${adItem.description}`}
                adItem={adItem}
              />
            ),
            content
          )}
        </CardList>
      </CardContainer>
    </Container>
  )
}

type ContainerProps = {
  __firstAd?: boolean
}
type AdCardProps = {
  adItem: BattleDemoAd
  className?: string
}

type Props = {
  adSection: BattleDemoAdSection
  className?: string
  firstAd?: boolean
}
export default AdCardSection
