import {
  DownloadButtons,
  DownloadInfo,
  DownloadLogo,
  hyphenLocale,
  NextLink
} from '@apeiron/library'
import { Box, styled } from '@mui/material'
import SeasonIconPNG from '@public/icons/icon-download-season-icon.png'
import SeasonBackgroundJPG from '@public/images/image-download-season-background.jpg'
import BattleDemoTitle from '@src/components/battle/demo/share/BattleDemoTitle'
import { FeatureFlagType } from '@src/constants/featureFlag'
import { GAEventCategory } from '@src/constants/googleAnalytics'
import { DEFAULT_LOCALE } from '@src/constants/locale'
import { ROUTE } from '@src/constants/route'
import featureAllowed from '@src/util/apeiron/featureAllowed'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

const Container = styled(Box)`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 1080px;
`

const Background = styled(Box)`
  background: url(${SeasonBackgroundJPG.src}) no-repeat center center;
  width: 100%;
  height: 1080px;
  object-fit: cover;
  position: absolute;
`

const Gradient = styled(Box)`
  background: linear-gradient(180deg, #12151c00 00%, #12151c 90%, #12151c 100%);
  height: 600px;
  position: absolute;
  top: calc(1080px - 600px);
  width: 100%;
`

const StyledDownloadLogo = styled(DownloadLogo)`
  .Custom-Colored-Text {
    color: #91edff;
    font-weight: 500;
    text-shadow: 0px -2px 10px #283451;
  }

  ${props => props.theme.breakpoints.up('res768')} {
    .Custom-Colored-Text {
      font-size: 20px;
      margin-bottom: 4px;
    }
    .Custom-Title {
      margin-bottom: 24px;
    }
  }
  ${props => props.theme.breakpoints.between('res480', 'res768')} {
    .Custom-Colored-Text {
      font-size: 20px;
      margin-bottom: 6px;
    }
    .Custom-Title {
      margin-bottom: 20px;
    }
  }
  ${props => props.theme.breakpoints.down('res480')} {
    .Custom-Colored-Text {
      font-size: 18px;
      margin-bottom: 4px;
    }
    .Custom-Title {
      margin-bottom: 16px;
    }
  }
`

const Title = styled(BattleDemoTitle)`
  align-self: flex-start;
  position: relative;
  ${props => props.theme.breakpoints.up('res1024')} {
    margin: 45px 0px 0px 90px;
  }
  ${props => props.theme.breakpoints.down('res1024')} {
    margin: 40px 0px 0px 20px;
  }
`

const Placeholder = styled(Box)`
  height: 64px;
  ${props => props.theme.breakpoints.up('res1024')} {
    margin: 45px 0px 0px 90px;
  }
  ${props => props.theme.breakpoints.down('res1024')} {
    margin: 40px 0px 0px 20px;
  }
`

const TextLink = styled(NextLink)`
  font-size: 16px;
  font-weight: 300;
  margin-top: 15px;
  text-decoration: underline;
  position: relative;
  ${props => props.theme.breakpoints.up('res1024')} {
    margin-left: 500px;
  }
`

const DownloadSection: FC<Props> = props => {
  const { infoList, showTitle } = props

  const { t } = useTranslation()

  return (
    <Container>
      <Background />
      <Gradient />
      {showTitle ? (
        <Title
          apeironTitle={t('battle_demo.download.page_title', {
            lng: hyphenLocale(DEFAULT_LOCALE)
          })}
          title={t('battle_demo.download.page_title')}
        />
      ) : (
        <Placeholder />
      )}
      <StyledDownloadLogo
        logo={SeasonIconPNG.src}
        subTitleKey={'battle_demo.download.icon_subtitle'}
        titleKey={'battle_demo.download.icon_title'}
      />
      <DownloadButtons
        analytic={{ emitById: true, event: GAEventCategory.Download }}
        infoList={infoList}
      />
      {featureAllowed(FeatureFlagType.ShowBattleDemoReleaseNote) && (
        <TextLink href={ROUTE.ACCOUNT_SETTING}>
          {`${t('battle_demo.release_note')} >>`}
        </TextLink>
      )}
      <TextLink
        href={
          'https://docs.google.com/document/d/1y61JZfBlupyxC7aUurVOAf0-n-lwStfTmkyIf-aMTT0/edit?usp=sharing'
        }
        target='_blank'
      >
        {`${t('battle_demo.faq')} >>`}
      </TextLink>
    </Container>
  )
}

type Props = {
  infoList: DownloadInfo[]
  showTitle: boolean
}

export default DownloadSection
