import { AssetType, useApeironPathMaker, useFetchJson } from '@apeiron/library'
import CONFIG from '@src/config'
import { PRELOAD_KEY } from '@src/constants/preload'
import { usePreloadContext } from '@src/contexts/share/PreloadContext'
import { battleDemoAdSectionSchema } from '@src/deserialize/yup/battleDemo'
import { BattleDemoAdSection } from '@src/types/battleDemo'
import * as R from 'ramda'
import { useCallback } from 'react'

const useFetchBattleDemoAdvertisementJson = (): Hook => {
  const { makePath } = useApeironPathMaker()

  const { preloadValue } = usePreloadContext()

  const { fetchJson: doFetch } = useFetchJson({
    url: makePath(
      AssetType.Json,
      CONFIG.JSON_FILE_NAME.BATTLE_DEMO_ADVERTISEMENT
    )
  })

  const fetchJson = useCallback(async (): Promise<BattleDemoAdSection[]> => {
    const response = (await doFetch()) as Object[]

    const sections = R.map((data: Record<string, any>): BattleDemoAdSection => {
      const section = battleDemoAdSectionSchema.cast(
        data
      ) as BattleDemoAdSection

      return section
    }, response || [])

    return sections
  }, [doFetch])

  const fetchWithPreload = useCallback(async () => {
    return preloadValue(PRELOAD_KEY.BATTLE_DEMO_ADVERTISEMENT, fetchJson)
  }, [fetchJson, preloadValue])

  return {
    fetchJson: fetchWithPreload
  }
}

type Hook = {
  fetchJson: () => Promise<BattleDemoAdSection[]>
}

export default useFetchBattleDemoAdvertisementJson
