import { noForwardProps, SkeletonImage } from '@apeiron/library'
import { Box, styled } from '@mui/material'
import BattleDemoTitle from '@src/components/battle/demo/share/BattleDemoTitle'
import ApeironPurpleDivider from '@src/components/share/apeiron/ApeironPurpleDivider'
import { DEFAULT_ANIMATE_DURATION } from '@src/constants/layout'
import { BattleDemoAdSection } from '@src/types/battleDemo'
import * as R from 'ramda'
import { FC } from 'react'

const Container = styled(Box, noForwardProps)<ContainerProps>`
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding-bottom: 50px;
  width: 100%;
  position: relative;
  ${props =>
    props.__firstAd &&
    'background: linear-gradient(180deg, #12151c00 0%,#12151c 10%, #12151c00 30%);'}
`

const Title = styled(BattleDemoTitle)`
  ${props => props.theme.breakpoints.up('res1024')} {
    margin: 45px 0px 0px 90px;
  }
  ${props => props.theme.breakpoints.down('res1024')} {
    margin: 40px 0px 0px 20px;
  }
`

const Divider = styled(ApeironPurpleDivider)`
  align-self: center;
  width: 100%;
  max-width: 1768px;
`

const BannerContainer = styled(Box)`
  display: flex;
  justify-content: center;
  width: 100%;
  transition: filter ${DEFAULT_ANIMATE_DURATION}ms;
  :hover {
    filter: brightness(1.2);
  }
`

const Banner = styled(SkeletonImage)`
  aspect-ratio: 1.987;
  max-width: 1200px;
  width: 90%;
`

const AdBannerSection: FC<Props> = props => {
  const { adSection, className, firstAd = false } = props

  const image = R.pathOr('', ['content', '0', 'image'], adSection)

  const apeironTitle = R.propOr('', 'apeironTitle', adSection) as string

  const title = R.propOr('', 'title', adSection) as string

  return (
    <Container __firstAd={firstAd} className={className}>
      <Title apeironTitle={apeironTitle} title={title} />
      <Divider />
      <BannerContainer>
        <Banner src={image} />
      </BannerContainer>
    </Container>
  )
}

type ContainerProps = {
  __firstAd?: boolean
}

type Props = {
  adSection: BattleDemoAdSection
  className?: string
  firstAd?: boolean
}

export default AdBannerSection
